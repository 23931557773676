<app-loading-spinner *ngIf="loadingUI === LoadingUI.Spinner" [isLoading]="isLoading"></app-loading-spinner>
<div class="bs-table no-wrap" [ngClass]="{ 'force-desktop-layout': forceDesktopLayout() }">
  <table class="table table-hover">
    <thead>
      <tr *ngIf="gridHeaderColumns.length === 1" class="thead" data-cy="tableHeader">
        <th *ngIf="checkAble" class="checkAble"></th>
        <th
          *ngFor="let column of gridHeaderColumns[0]"
          (click)="onHeaderClick(column)"
          [ngStyle]="column.headStyles ?? {}"
          [ngClass]="column.headClasses ?? {}"
          [class.sortable]="sortAble"
        >
          <span *ngIf="sortField === column.field">
            <i class="fas" [class.fa-arrow-up]="sortOrder === 'asc'" [class.fa-arrow-down]="sortOrder === 'desc'"></i>
          </span>
          {{ column.display ?? column.field }}
          @if (column.isBeta) {
            <span class="badge rounded-pill bg-pink badge-new"> beta </span>
          }
        </th>
      </tr>
      <ng-container *ngIf="gridHeaderColumns.length === 2">
        <tr *ngFor="let gridHeaderRow of gridHeaderColumns; let i = index" class="thead" data-cy="tableHeader">
          <th rowspan="2" *ngIf="checkAble && i === 0" class="checkAble"></th>
          <th
            *ngFor="let column of gridHeaderRow"
            (click)="onHeaderClick(column)"
            [ngStyle]="column.headStyles ?? {}"
            [ngClass]="column.headClasses ?? {}"
            [class.sortable]="sortAble"
            [colSpan]="column.colspan ?? 1"
            [rowSpan]="column.rowspan ?? 1"
          >
            <span *ngIf="sortField === column.field">
              <i class="fas" [class.fa-arrow-up]="sortOrder === 'asc'" [class.fa-arrow-down]="sortOrder === 'desc'"></i>
            </span>
            {{ column.display ?? column.field }}
            @if (column.isBeta) {
              <span class="badge rounded-pill bg-pink badge-new">beta</span>
            }
          </th>
        </tr>
      </ng-container>
      <tr *ngIf="hasFilters" class="tfilter" data-cy="tableFilter">
        <th *ngIf="checkAble" class="checkAble">
          <app-square-checkmark [(value)]="checkAll" (valueChange)="onCheckAll($event)"></app-square-checkmark>
        </th>
        <th *ngFor="let column of gridBodyColumns">
          <app-data-grid-filter
            [value]="column.filterDefaultValue"
            [column]="column"
            (filterChanged)="onFilterChanged($event.value, $event.column)"
            [isLoading]="isLoading"
          ></app-data-grid-filter>
        </th>
      </tr>
      <ng-content select="[summaryTop]" />
    </thead>
    <tbody class="border-0" data-cy="products">
      <tr *ngIf="!data.length && !isLoading">
        <td [colSpan]="gridBodyColumns.length + (checkAble ? 1 : 0)" class="empty-table">
          <div class="alert alert-warning" role="alert">
            <div class="alert-message" data-cy="emptyTableText">No results found</div>
          </div>
        </td>
      </tr>
      <ng-container *ngIf="isLoading && loadingUI === LoadingUI.Placeholder && (!showLoadingInitOnly || data.length <= 0)">
        <tr *ngFor="let _ of [].constructor(loadingRowsCount)">
          <td *ngFor="let column of gridBodyColumns" class="placeholder-glow">
            <span class="placeholder placeholder-lg col-12">L</span>
          </td>
        </tr>
      </ng-container>
      <ng-container *ngIf="loadingUI !== LoadingUI.Placeholder || !isLoading || showLoadingInitOnly">
        <tr
          *ngFor="let item of data; let i = index"
          data-cy="tableRow"
          [ngClass]="{ 'table-dark': isItemActive(item), realtimeLoadedRow: showLoadingInitOnly && data.length <= 0 }"
        >
          <td *ngIf="checkAble" class="checkAble">
            <app-square-checkmark [(value)]="checkedRows[i].checked" (valueChange)="onCheckItem($event, i)"></app-square-checkmark>
          </td>
          <td
            *ngFor="let column of gridBodyColumns"
            (click)="onItemClick(item, column)"
            data-cy="tableRowItem"
            [ngStyle]="column.rowStyles ?? {}"
          >
            <span class="cell-label">{{ column.display ?? column.field }}</span>
            <span
              class="cell-value"
              *ngIf="column.templateRef"
              [ngTemplateOutlet]="templates[column.templateRef]"
              [ngTemplateOutletContext]="{ item, index: i, column }"
            ></span>
            <span class="cell-value" data-cy="tableRowItemValue" *ngIf="!column.templateRef">{{ item | gridField: column }}</span>
          </td>
        </tr>
      </ng-container>
      <ng-content select="[summaryBottom]" />
    </tbody>
  </table>
</div>
