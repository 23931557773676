<ng-container *ngIf="column && column.filter" [ngSwitch]="column.type">
  <ng-container *ngSwitchCase="'date'">
    <chitin-daterange-picker
      *ngIf="column.filterType === 'daterange'"
      type="input"
      size="small"
      appendTo="body"
      [isDisabled]="isLoading"
      [placeholder]="column.display ?? column.field"
      [onlyApplyOnValueChange]="true"
      [value]="value"
      (valueChange)="filterChange($event, column)"
    />
    <chitin-form-input
      *ngIf="column.filterType !== 'daterange'"
      type="date"
      size="small"
      [isDisabled]="isLoading"
      [placeholder]="column.display ?? column.field"
      [value]="value"
      (valueChange)="filterChange($event, column)"
      format="MM/dd/yyyy"
    />
  </ng-container>
  <ng-container *ngSwitchCase="'datetime'">
    <chitin-daterange-picker
      *ngIf="column.filterType === 'daterange'"
      type="input"
      size="small"
      appendTo="body"
      [isDisabled]="isLoading"
      [placeholder]="column.display ?? column.field"
      [onlyApplyOnValueChange]="true"
      [value]="value"
      (valueChange)="dateRangeValueChange($event, column)"
    />
    <chitin-form-input
      *ngIf="column.filterType !== 'daterange'"
      type="date"
      size="small"
      [isDisabled]="isLoading"
      [placeholder]="column.display ?? column.field"
      [value]="value"
      (valueChange)="filterChange($event, column)"
      format="MM/dd/yyyy"
    />
  </ng-container>
  <chitin-select-old
    *ngSwitchCase="'multi-select'"
    [isDisabled]="isLoading"
    [items]="column.filterOptions ?? []"
    [itemsStream]="column.filterOptionsStream"
    [placeholder]="column.display ?? column.field"
    [clearable]="true"
    [searchable]="true"
    [displayNumberOfSelected]="true"
    [multiple]="true"
    valueType="value"
    [value]="value"
    (valueChange)="filterChange($event, column)"
    displayExpr="name"
    valueExpr="value"
    size="sm"
  />
  <chitin-select-old
    *ngSwitchCase="'select'"
    [isDisabled]="isLoading"
    [items]="column.filterOptions ?? []"
    [itemsStream]="column.filterOptionsStream"
    [placeholder]="column.display ?? column.field"
    [clearable]="true"
    [searchable]="true"
    valueType="value"
    [value]="value"
    (valueChange)="filterChange($event, column)"
    displayExpr="name"
    valueExpr="value"
    size="sm"
  />
  <chitin-form-input
    *ngSwitchDefault
    [isDisabled]="isLoading"
    [emissionDelayInMs]="500"
    [placeholder]="column.display ?? column.field"
    [value]="value"
    (valueChange)="filterChange($event, column)"
    size="small"
  />
</ng-container>
