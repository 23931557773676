import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataGridFilterChanged } from '@shared/components/data-grid/data-grid-events';
import { GridColumn } from '@shared/models/grid-column.model';

@Component({
  selector: 'app-data-grid-filter',
  templateUrl: './data-grid-filter.component.html',
})
export class DataGridFilterComponent {
  @Input() column?: GridColumn;
  @Input() value?: any;
  @Input() isLoading: boolean = false;
  @Output()
  filterChanged = new EventEmitter<DataGridFilterChanged>();

  filterChange(value: any, column: GridColumn) {
    this.filterChanged.next({ value, column });
  }

  dateRangeValueChange(value: any, column: GridColumn) {
    this.filterChange(
      {
        from: value?.from,
        to: value?.to,
      },
      column,
    );
  }
}
