import { inject, Injectable } from '@angular/core';
import { AuthGuard } from '@services/guards/auth-guard';
import { PharmaciesSearchStore } from '@services/pharmacies.search.store';
import { SettingsStore } from '@services/settings.store';
import { InventorySettings } from '@shared/models/settings/invetory-settings';
import { TenantIdType } from '@shared/models/tenant-id.type';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthInventoryGuard extends AuthGuard {
  private readonly pharmaciesStore = inject(PharmaciesSearchStore);
  private readonly settingsStore = inject(SettingsStore);

  async isAccessGranted(componentName: string, featureFlagKey: string, params: Record<string, string>) {
    const tenantId = this.getTenantByComponent(componentName);
    const access = await super.isAccessGranted(componentName, featureFlagKey, params);
    const granted = access && (await this.isInventoryAvailable(params.ncpdp, tenantId));
    if (!granted) console.warn('AuthInventoryGuard', { ncpdp: params.ncpdp, tenantId });
    return granted;
  }

  async isInventoryAvailable(ncpdp: string, tenantId?: string) {
    if (!ncpdp) {
      console.error('AuthInventoryGuard: Param ncpdp is required', ncpdp);
      return false;
    }

    if (tenantId === 'ALM') {
      return await this.isAvailableALM(ncpdp);
    } else if (tenantId === 'INCY') {
      return await this.isAvailableINCY(ncpdp);
    }

    const checks = await Promise.all([this.isAvailableALM(ncpdp), this.isAvailableINCY(ncpdp)]);
    return checks.some(value => !!value);
  }

  async isAvailableALM(ncpdp: string) {
    const tenantId = 'ALM';

    if (!(this.userService.getCurrentUser().tenants ?? []).includes(tenantId as TenantIdType)) {
      console.warn('AuthScorecardGuard: User is not allowed for tenant', tenantId, ncpdp);
      return false;
    }

    const pharmacy = await lastValueFrom(this.pharmaciesStore.get(ncpdp));
    const enrollment = pharmacy?.enrollments.find(enrollment => enrollment.tenantId === tenantId);

    return !!enrollment?.isConsignmentHub && !enrollment?.programGroupIsBlocked;
  }

  async isAvailableINCY(ncpdp: string) {
    const tenantId = 'INCY';

    if (!(this.userService.getCurrentUser().tenants ?? []).includes(tenantId as TenantIdType)) {
      console.warn('AuthScorecardGuard: User is not allowed for tenant', tenantId, ncpdp);
      return false;
    }

    const [pharmacy, settings] = await Promise.all([
      await lastValueFrom(this.pharmaciesStore.get(ncpdp)),
      await lastValueFrom(this.settingsStore.getSettings<InventorySettings>('inventory', `tenant:${tenantId}`)),
    ]);
    const enrollment = pharmacy?.enrollments.find(enrollment => enrollment.tenantId === tenantId);

    if (!settings) {
      console.error('AuthInventoryGuard: Settings missing for tenant', tenantId);
      return false;
    }

    if (!enrollment) {
      console.error('AuthInventoryGuard: Enrollment missing for pharmacy and tenant', ncpdp, tenantId);
      return false;
    }

    return settings.visibility.programGroup[enrollment.programGroup] ?? false;
  }

  private getTenantByComponent(componentName: string) {
    if (componentName === 'AdminPharmacyInventoryWrapperComponent' || componentName === 'PortalPharmacyInventoryWrapperComponent') {
      return 'ALM';
    } else if (componentName === 'PharmacyInventoryLogWrapperComponent' || componentName === 'PharmacyInventoryLogWrapperComponent') {
      return 'INCY';
    }
  }
}
