import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import config from '@config';
import { OpenSearchRequest } from '@libs/shared/domain/aggregations/opensearch/open-search-query.schema';
import { OpenSearchQuerySimpleResult } from '@shared/providers/opensearch-api';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OpenSearchClient {
  static readonly OPEN_SEARCH_LIMIT = 10_000;
  private readonly baseUrl = config.openSearchUrl;
  private http = inject(HttpClient);

  search<T>(index: string, query: OpenSearchRequest): Observable<OpenSearchQuerySimpleResult<T>> {
    const url = `${this.baseUrl}/${index}/search`;
    return this.http.post<OpenSearchQuerySimpleResult<T>>(url, query);
  }

  count(index: string, query: OpenSearchRequest): Observable<{ count: number }> {
    const url = `${this.baseUrl}/${index}/count`;
    return this.http.post<{ count: number }>(url, query);
  }

  export(index: string, query: OpenSearchRequest, params: Record<string, string> = {}): Observable<{ fileUrl: string }> {
    const url = `${this.baseUrl}/${index}/export?${new URLSearchParams(params).toString()}`;
    return this.http.post<{ fileUrl: string }>(url, query);
  }
}
