import gql from 'graphql-tag';

export const GetProductsQuery = gql`
  query GetProducts($tenantId: String!) {
    tenant(id: $tenantId) {
      products {
        edges {
          cursor
          node {
            _id
            brand
            dosage
            howSupplied
            name
            ndc
            ndc9
            wac
            quantityUnit
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
        totalCount
      }
    }
  }
`;
