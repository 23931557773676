import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { CopayCodeRequestSearch, ICopayCodeRequestSearchResult } from '@shared/models/copay-code-request-search.model';
import { UrlService } from '@services/url.service';
import { ICopayCodeRequest } from '@shared/models/copay-code-request.model';
import Utils from '@shared/providers/utils';
import { ApiList } from '@shared/models/api-list.model';
import { CopayCodeRequestScorecardRequest } from '@shared/models/copay-code-request-scorecard-model';
import { TenantIdType } from '@shared/models/tenant-id.type';
import { CodeRequestScorecardResponse } from '@shared/models/copay/code-request-scorecard.response';
import { CopayCodeRequestCouponModel, CopayCodeRequestCouponResponse } from '@shared/models/copay-code-request-coupon.model';

export interface SendEmailParams {
  ncpdp: string;
  created: string;
  tenant: string;
  email: string;
  subject: string;
  message: string;
  isHtml?: boolean;
}

export interface SendSMSParams {
  ncpdp: string;
  created: string;
  tenant: string;
  phoneNumber: string;
  source?: string;
  message?: string;
  smsProgram?: string;
  keyword?: string;
}

@Injectable({
  providedIn: 'root',
})
export class CopayCodeRequestApiService {
  constructor(
    private http: HttpClient,
    private urlService: UrlService,
  ) {}

  getCodeRequest(ncpdp: string, created: string, tenantId?: TenantIdType, token?: string): Observable<ICopayCodeRequest> {
    let url = this.urlService.COPAY_CODE_REQUESTS_URL;
    if (tenantId) {
      url = url.replace('{tenant}', tenantId);
    }
    url += `/${ncpdp}-${created}`;
    if (token) {
      url += `?token=${token}`;
    }

    return this.http.get<ICopayCodeRequest>(url);
  }

  updateCodeRequest(codeRequest: Partial<ICopayCodeRequest>, tenantId?: TenantIdType): Observable<any> {
    const _codeRequest = Utils.copyObject(codeRequest);
    Utils.deleteEmptyFields(_codeRequest);
    let url = this.urlService.COPAY_CODE_REQUESTS_URL;
    if (tenantId) {
      url = url.replace('{tenant}', tenantId);
    }
    const pbaToken = localStorage.getItem('pbaToken');
    if (pbaToken !== null) {
      url += `?token=${pbaToken}`;
    }
    const headers = new HttpHeaders();

    return this.http.put(url, _codeRequest, { headers: headers });
  }

  patchCodeRequest(
    codeRequest: Partial<ICopayCodeRequest> & Pick<ICopayCodeRequest, 'ncpdp'> & Pick<ICopayCodeRequest, 'created'>,
    tenantId?: TenantIdType,
    token?: string,
  ): Observable<void> {
    const _codeRequest = Utils.copyObject(codeRequest);
    Utils.deleteEmptyFields(_codeRequest);
    let url = this.urlService.COPAY_CODE_REQUESTS_URL;
    if (tenantId) {
      url = url.replace('{tenant}', tenantId);
    }
    if (token) {
      url += `?token=${token}`;
    }

    return this.http
      .patch(
        url,
        {
          new_ccr_data: _codeRequest,
        },
        { responseType: 'text' },
      )
      .pipe(map(() => {}));
  }

  searchCodeRequests(
    copayCodeRequestSearchFields: Partial<CopayCodeRequestSearch>,
    reverseOrder = true,
    tenant?: TenantIdType | string,
  ): Observable<ApiList<ICopayCodeRequestSearchResult>> {
    const _copayCodeRequestSearchFields: Record<string, any> = Utils.copyObject(copayCodeRequestSearchFields);
    if (!reverseOrder) {
      _copayCodeRequestSearchFields['reverse'] = 0;
    }

    let url = this.urlService.COPAY_CODE_REQUESTS_URL;
    if (tenant) {
      url = url.replace('{tenant}', tenant);
    }

    return this.http.post<ApiList<ICopayCodeRequestSearchResult>>(url, _copayCodeRequestSearchFields);
  }

  tokenSearch(copayCodeRequestSearchFields: Partial<CopayCodeRequestSearch>) {
    return this.http.post<ApiList<ICopayCodeRequestSearchResult>>(
      this.urlService.COPAY_CODE_REQUESTS_URL + '',
      copayCodeRequestSearchFields,
    );
  }

  getCodeRequestScorecard(
    search_criteria: CopayCodeRequestScorecardRequest,
    tenantId?: TenantIdType,
  ): Observable<CodeRequestScorecardResponse> {
    let url = this.urlService.COPAY_SCORECARD_URL;
    if (tenantId) {
      url = url.replace('{tenant}', tenantId);
    }
    return this.http.post<any>(`${url}`, JSON.stringify(search_criteria));
  }

  sendEmail(params: SendEmailParams) {
    const url = this.urlService.COPAY_ACTIONS_URL.replace('{tenant}', params.tenant);
    return this.http.post(url, {
      ncpdp: params.ncpdp,
      created: params.created,
      action: 'email',
      email_address: params.email,
      subject: params.subject,
      message: params.message,
      as_html: params.isHtml,
    });
  }

  sendSMS(params: SendSMSParams) {
    const url = this.urlService.COPAY_ACTIONS_URL.replace('{tenant}', params.tenant);
    return this.http.post<{ message_id: string; status: string }>(url, {
      ncpdp: params.ncpdp,
      created: params.created,
      action: 'sms',
      subscriber_number: params.phoneNumber,
      message: params.message,
      source: params.source,
      sms_program: params.smsProgram,
      keyword: params.keyword,
    });
  }

  createCoupon(data: CopayCodeRequestCouponModel): Observable<CopayCodeRequestCouponResponse> {
    return this.http.post<CopayCodeRequestCouponResponse>(this.urlService.COPAY_CODE_REQUESTS_COUPON_URL, data);
  }
}
