import { ActivatedRouteSnapshot, Route, Routes } from '@angular/router';
import { match } from 'path-to-regexp';

class RouterExplorer {
  private routes: Record<string, Route> = {};

  getRoutes() {
    return this.routes;
  }

  addRoutes(routes: Routes, prefix = '') {
    for (const route of routes) {
      let path = prefix;
      if (route.path) path += `/${route.path}`;
      if (route.component || route.data || route.children || route.loadChildren) this.routes[path] = route;
      if (route.children?.length) this.addRoutes(route.children, path);
    }
  }

  findRoute(url: string) {
    for (const [path, route] of Object.entries(this.routes)) {
      const urlMatch = match(path)(url);
      if (urlMatch) return route;
    }
  }

  getParams(url: string): Record<string, string> {
    for (const [path] of Object.entries(this.routes)) {
      const urlMatch = match(path)(url);
      if (urlMatch) {
        const params: Record<string, string> = {};
        Object.entries(urlMatch.params).forEach(([key, value]) => (params[key] = String(value)));
        return params;
      }
    }
    return {};
  }

  getRouteUrl(route: ActivatedRouteSnapshot) {
    let result = '';
    let parent: ActivatedRouteSnapshot | null = route;
    while (parent) {
      const url = parent.url.map(item => item.path).join('/');
      result = `${url ? '/' : ''}${url}${result}`;
      parent = parent.parent;
    }
    return result;
  }
}

export const RoutesExplorer = new RouterExplorer();
