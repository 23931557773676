import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { LogService } from '@services/log.service';
import { OpenSearchQuery, OpenSearchQueryResult, OpenSearchSimpleSort } from '@shared/providers/opensearch-api';
import { OpenSearchClient } from '@shared/providers/opensearch-client';
import { of } from 'rxjs';

export type GroupByField = { field: string; groupBy: string };
export type GroupMap = Record<string, Record<string, unknown[]>>;

@Injectable()
export class OpenSearchService {
  protected client = inject(OpenSearchClient);
  protected logger = inject(LogService);

  createSearchSort(sort: OpenSearchSimpleSort): OpenSearchQuery['sort'] {
    return Object.entries(sort).map(([key, order]) => {
      return { [key]: { order } };
    });
  }

  /**
   * @deprecated Use processSearchError instead
   * @see processSearchError
   */
  protected processSimpleSearchError<T>(error: HttpErrorResponse) {
    return this.processSearchError<T>(error);
  }

  protected processSearchError<T>(error: HttpErrorResponse) {
    this.logger.error(error);
    return of(this.getEmptyResult<T>(error));
  }

  protected getEmptyResult<T>(error: HttpErrorResponse): OpenSearchQueryResult<T> {
    return { error, items: [], total: 0 };
  }
}
