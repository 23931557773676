import { Injectable } from '@angular/core';
import { GetAllProducts, GetProduct, GetProducts, Product } from '@generated/graphql';
import { GraphqlService } from '@services/graphql.service';
import { GetAllProductsQuery } from '@services/graphql/products/get-all-products.query';
import { GetProductQuery } from '@services/graphql/products/get-product.query';
import { GetProductsQuery } from '@services/graphql/products/get-products.query';
import { SelectListItem } from '@shared/models/select-list-item';
import { chain } from 'lodash';
import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProductsService extends GraphqlService {
  get(variables: GetProduct.Variables): Observable<Product | undefined> {
    return this.graphqlClient
      .query<GetProduct.Query, GetProduct.Variables>(GetProductQuery, variables)
      .pipe(map(response => response.data?.tenant?.product ?? undefined));
  }

  getList(tenantId: string): Observable<Product[]> {
    return this.graphqlClient.query<GetProducts.Query, GetProducts.Variables>(GetProductsQuery, { tenantId }).pipe(
      map(result => {
        this.processGraphqlQueryResult(result);
        return this.mapProducts(result.data?.tenant?.products.edges.map(edge => edge.node) ?? []);
      }),
    );
  }

  getAll(): Observable<Record<string, Product[]>> {
    const tenantIds = this.tenantService.getActiveTenants().map(tenant => tenant.id);
    return this.graphqlClient.query<GetAllProducts.Query, GetAllProducts.Variables>(GetAllProductsQuery, { tenantIds }).pipe(
      map(result => {
        this.processGraphqlQueryResult(result);
        const productsMap: Record<string, Product[]> = {};
        result.data?.tenants?.forEach(tenant => (productsMap[tenant.id] = this.mapProducts(tenant.products.edges.map(edge => edge.node))));
        return productsMap;
      }),
    );
  }

  getSelectList(tenantId: string): Observable<SelectListItem[]> {
    return this.getList(tenantId).pipe(map(products => this.mapSelectList(products)));
  }

  mapSelectList(products: Product[]): SelectListItem[] {
    return chain(products)
      .map(product => ({ name: `${product.name ?? 'UNKNOWN'}`, value: product.ndc }))
      .value();
  }

  private mapProducts(products: Product[]): Product[] {
    return chain(products)
      .filter(product => product.ndc !== '16110024430x')
      .map(product => ({ ...product, name: `${product.name ?? 'UNKNOWN'}` }))
      .sortBy(item => item.name.toLowerCase())
      .value();
  }
}
