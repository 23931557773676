import { inject, Injectable } from '@angular/core';
import { AuthGuard } from '@services/guards/auth-guard';
import { PharmaciesSearchStore } from '@services/pharmacies.search.store';
import { SettingsStore } from '@services/settings.store';
import { ScorecardSettings } from '@shared/models/settings/scorecard-settings';
import { TenantIdType } from '@shared/models/tenant-id.type';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthScorecardGuard extends AuthGuard {
  private readonly TENANTS = ['ALM', 'INCY'];
  private readonly pharmaciesStore = inject(PharmaciesSearchStore);
  private readonly settingsStore = inject(SettingsStore);

  async isAccessGranted(componentName: string, featureFlagKey: string, params: Record<string, string>) {
    const access = await super.isAccessGranted(componentName, featureFlagKey, params);
    const granted = access && (await this.isScorecardAvailable(params));
    if (!granted) console.warn('AuthScorecardGuard', params);
    return granted;
  }

  async isScorecardAvailable(params: Record<string, string>) {
    if (params.tenantId) {
      return await this.isScorecardTenantAvailable(params);
    }

    const checks = await Promise.all(this.TENANTS.map(tenantId => this.isScorecardTenantAvailable({ ...params, tenantId })));
    return checks.some(value => !!value);
  }

  async isScorecardTenantAvailable(params: Record<string, string>) {
    const ncpdp = params.ncpdp;
    const tenantId = params.tenantId;

    if (!ncpdp || !tenantId) {
      console.error('AuthScorecardGuard: Params ncpdp and tenantId are required', ncpdp, tenantId);
      return false;
    }

    if (!(this.userService.getCurrentUser().tenants ?? []).includes(tenantId as TenantIdType)) {
      console.warn('AuthScorecardGuard: User is not allowed for tenant', tenantId, ncpdp);
      return false;
    }

    const [pharmacy, settings] = await Promise.all([
      await lastValueFrom(this.pharmaciesStore.get(ncpdp)),
      await lastValueFrom(this.settingsStore.getSettings<ScorecardSettings>('scorecard', `tenant:${tenantId}`)),
    ]);
    const enrollment = pharmacy?.enrollments.find(enrollment => enrollment.tenantId === tenantId);

    if (!settings) {
      console.error('AuthScorecardGuard: Settings missing for tenant', tenantId);
      return false;
    }

    if (!enrollment) {
      console.error('AuthScorecardGuard: Enrollment missing for pharmacy and tenant', ncpdp, tenantId);
      return false;
    }

    if (enrollment.engagementsBrightscripIsScorecardEnabled !== undefined) {
      return enrollment.engagementsBrightscripIsScorecardEnabled;
    }

    return settings.visibility.programGroup[enrollment.programGroup] ?? false;
  }
}
